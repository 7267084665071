import React, { useEffect, useState } from "react";

function EditSeo({ seo, seoUpdate, seoDelete }) {
    const [seoDetail, setSeoDetail] = useState({ seo });
    useEffect(() => {
        setSeoDetail(seo)
    }, [seo]);

    return (
        <div className="col-md-12">
            <hr className="mt-0" />
            <div className="row align-items-end">
                <div className="form-group col-md-6 mb-3">
                    <label>Title</label>
                    <input
                        className="form-control form-control-lg"
                        onChange={(e) => setSeoDetail({
                            ...seoDetail,
                            meta_title: e.target.value,
                        })}
                        value={seoDetail.meta_title}
                    />
                </div>
                <div className="form-group col-md-6 mb-3">
                    <label>Keyword</label>
                    <input
                        className="form-control form-control-lg"
                        onChange={(e) => setSeoDetail({
                            ...seoDetail,
                            meta_keyword: e.target.value,
                        })}
                        value={seoDetail.meta_keyword}
                    />
                </div>
                <div className="form-group col-md-12 mb-3">
                    <label>Description</label>
                    <textarea
                        className="form-control form-control-lg"
                        onChange={(e) => setSeoDetail({
                            ...seoDetail,
                            meta_description: e.target.value,
                        })}
                        value={seoDetail.meta_description}
                    />
                </div>

            </div>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                    <button className="btn btn-primary mr-2" onClick={() => seoUpdate(seoDetail)}>Update</button>
                    <button className="btn btn-danger" onClick={() => seoDelete(seoDetail.id)}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditSeo