import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory, Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import { ErrorHandler } from "../../ui/ErrorHandler";
import TextEditor from "../../ui/TextEditor";
import CustomAutocomplete from './../../ui/CustomAutocomplete';
import SelectSearch from 'react-select-search';
import "./Services.css";
import PageSection from "../PageSection/PageSection";
import Faq from "../FAQ/Faq";
import Seo from "../Seo/Seo";


function EditService(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [service, setService] = useState({});
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(0);
    const [image, setImage] = useState(null);
    const [seo, setSeo] = useState({});
    const [serviceId, setServiceId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );

    const types = [
        { name: 'Dynamic Content ', value: '0' },
        { name: 'Static Content ', value: '1' }
    ];
    const [pageType, setType] = useState(0);
    useEffect(() => {
        getServiceDetails();
        getCategories();
    }, [props.match.params.id]);
    const getServiceDetails = () => {
        axios(`admin/services/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setService(response.data.data.service);
                    setCategoryId(response.data.data.service.categories[0].id);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const getCategories = () => {
        axios("admin/category_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setCategories(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", service.name);
        formdata.append("name_ar", service.name_ar);
        formdata.append("slug", service.slug);
        formdata.append("description", service.description);
        formdata.append("description_ar", service.description_ar);
        formdata.append("short_description", service.short_description);
        formdata.append("short_description_ar", service.short_description_ar);
        formdata.append("page_type", service.page_type);
        formdata.append("parent_id", service.parent_id);
        formdata.append("is_featured", service.is_featured ? 1 : 0);
        formdata.append("status", service.status ? 1 : 0);
        formdata.append("category_id", categoryId);
        formdata.append("image", image);
        formdata.append("id", serviceId);
        axios("admin/service_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    history.push({
                        pathname: "/services",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/services"
                    >
                        Services
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        to="#"
                        aria-current="page"
                    >
                        Edit Services
                    </Link>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid  mt-5">
                <div className="row">
                    <div className="col-md-12">
                        {Object.keys(service).length != 0 && (
                            <form method="POST" className="service-form">
                                <h3 className="edit-title">Edit Services <i class="fi fi-rr-file-edit"></i></h3>
                                <hr></hr>
                                <Grid container spacing={2}>
                                    {/* <Grid item xs={12}>
                                        <SelectSearch options={services} id="parent" value={service.parent_id} onChange={(e) => setService({
                                            ...service,
                                            parent_id: e,
                                        })}
                                            name="parent" search fuzzySearch placeholder="Choose your Parent Service" />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <SelectSearch options={categories} id="category" value={categoryId} onChange={(e) => setCategoryId(e)}
                                            name="category_id" search fuzzySearch placeholder="Choose Category" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectSearch options={types} id="page_type" value={service.page_type} onChange={(e) => setService({
                                            ...service,
                                            page_type: e,
                                        })}
                                            name="page_type" search fuzzySearch placeholder="Choose your Page Type" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Slug" variant="outlined" onChange={(e) => setService({
                                            ...service,
                                            slug: e.target.value,
                                        })} value={service.slug} name="slug" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setService({
                                            ...service,
                                            name: e.target.value,
                                        })} value={service.name} name="name" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setService({
                                            ...service,
                                            name_ar: e.target.value,
                                        })} value={service.name_ar} name="name_ar" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description<br></br>
                                            <TextEditor defaultValue={service.description} onChange={(e) => setService({
                                                ...service,
                                                description: e,
                                            })} />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description Arabic<br></br>
                                            <TextEditor defaultValue={service.description_ar} onChange={(e) => setService({
                                                ...service,
                                                description_ar: e,
                                            })} />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Short Description<br></br>
                                            <textarea className="description" rows={4} cols={40} onChange={(e) => setService({
                                                ...service,
                                                short_description: e.target.value,
                                            })} value={service.short_description} name="short_description" />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Short Description Arabic<br></br>
                                            <textarea className="description" rows={4} cols={40} onChange={(e) => setService({
                                                ...service,
                                                short_description_ar: e.target.value,
                                            })} value={service.short_description_ar} name="short_description_ar" />
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <label>
                                            Upload Image<br></br>
                                            <input type="file" onChange={(e) => setImage(e.target.files[0])} className="upload-img" ></input>
                                        </label>
                                        <img width={100} height={100} src={service.image_url}></img>
                                    </Grid>
                                    {/* <Grid item xs={6} >
                                        <label>
                                            Upload Image Arabic<br></br>
                                            <input type="file" className="upload-img" ></input>
                                        </label>
                                    </Grid> */}
                                    <Grid item xs={6} >
                                        <label className="label-description">
                                            Is Featured
                                            <Switch
                                                checked={service.is_featured}
                                                onChange={() => setService({
                                                    ...service,
                                                    is_featured: !service.is_featured,
                                                })}
                                                color="primary"
                                            />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <label className="label-description">
                                            Status
                                            <Switch
                                                checked={service.status}
                                                onChange={() => setService({
                                                    ...service,
                                                    status: !service.status,
                                                })}
                                                color="primary"
                                            />
                                        </label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </div>

                </div>
            </div>
            <div class="container-fluid  mt-5">
                <Seo page={'Services'} pageId={serviceId}></Seo>
            </div>
            <div class="container-fluid  mt-5">
                <Faq page={'service'} pageId={serviceId}></Faq>
            </div>
            <div class="container-fluid  mt-5">
                <PageSection page={'service'} pageId={serviceId}></PageSection>
            </div>
        </div>
    );
}
export default EditService;