import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from "@mui/material";
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { ErrorHandler } from "../../ui/ErrorHandler";
import SelectSearch from 'react-select-search';
import "./PageSection.css";
import EditPageSectionItem from "./EditPageSectionItem";
function EditPageSection(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [pageSection, setPageSection] = useState({});
    const [content, setContent] = useState('');
    const [heading, setHeading] = useState('');
    const [contentAr, setContentAr] = useState('');
    const [headingAr, setHeadingAr] = useState('');
    const [image, setImage] = useState('');
    const [editImage, setEditImage] = useState('');
    const [sortOrder, setSortOrder] = useState(1);
    const [sections, setSections] = useState([]);
    const [pageSectionItems, setPageSectionItems] = useState([]);
    const [pageSectionId, setPageSectionId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    useEffect(() => {
        getPageSectionDetails();
        getSections();
        getPageSectionItems();
    }, [props.match.params.id]);
    const getPageSectionDetails = () => {
        axios(`admin/page_sections/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setPageSection(response.data.data.page_section);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const getPageSectionItems = () => {
        axios(`admin/page_section_item/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setPageSectionItems(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const pageSectionUpdate = () => {

        setLoading(true);
        let formData = new FormData();
        formData.append("content", pageSection.content);
        formData.append("heading", pageSection.heading);
        formData.append("content_ar", pageSection.content_ar);
        formData.append("heading_ar", pageSection.heading_ar);
        formData.append("section_id", pageSection.section_id);
        formData.append("sort_order", pageSection.sort_order);
        formData.append("image", editImage);
        formData.append("id", pageSectionId);

        axios("admin/update_page_section", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getPageSectionItems();
                    getPageSectionDetails();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const getSections = () => {
        axios(`admin/sections`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setSections(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const addPageSectionItem = (event) => {
        event.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append("page_section_id", pageSectionId);
        formData.append("heading", heading);
        formData.append("content", content);
        formData.append("heading_ar", headingAr);
        formData.append("content_ar", contentAr);
        formData.append("sort_order", sortOrder);
        formData.append("image", image);
        axios("admin/save_page_section_item", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    setContent('');
                    setHeading('');
                    setImage('');
                    setSortOrder(1);
                    getPageSectionItems();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const pageSectionItemUpdate = (pageSectionItem) => {

        setLoading(true);
        let formData = new FormData();
        formData.append("content", pageSectionItem.content);
        formData.append("content_ar", pageSectionItem.content_ar);
        formData.append("heading", pageSectionItem.heading);
        formData.append("heading_ar", pageSectionItem.heading_ar);
        formData.append("image", pageSectionItem.image);
        formData.append("sort_order", pageSectionItem.sort_order);
        formData.append("id", pageSectionItem.id);
        axios("admin/update_page_section_item", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getPageSectionItems();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const pageSectionItemDelete = (id) => {
        //setLoading(true);
        let formData = new FormData();
        formData.append("id", id);

        axios("admin/delete_page_section_item", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getPageSectionItems();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div>
            <div class="container-fluid">
                <div className={pageSection && pageSection.section && pageSection.section.name}>
                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" to="/">
                                Home
                            </Link>
                            <Link
                                underline="hover"
                                color="inherit"
                                to="/services"
                            >
                                Services
                            </Link>
                            <Link
                                underline="hover"
                                color="inherit"
                                to={"/edit-service/" + pageSection.page_id}
                            >
                                Edit Services
                            </Link>
                            {/* <a href="#" onClick={() => history.goBack()}>Edit Services</a> */}
                            <Link
                                underline="hover"
                                color="text.primary"
                                to="#"
                                aria-current="page"
                            >
                                Edit Page Section
                            </Link>
                        </Breadcrumbs>
                        <hr></hr>
                    </div>
                    <h3>{pageSection && pageSection.page_name}</h3>
                    <div className="container-fluid   mt-5 ">

                        <div className="row">
                            <Grid container spacing={2}>

                                <div className="col-md-12">
                                    <h4>Selected Theme</h4>
                                    <div className="row ">
                                        <div className="col-md-6 ">
                                            <img src={pageSection && pageSection.section && pageSection.section.sample_view} className="img-fluid" ></img>
                                        </div>
                                        {/* {sections.length > 0 ? sections.map((value, index) => {
                                return (



                                    <div className="col-md-3 ">
                                        
                                         <div className="section-box">
                                            <input type="radio" readOnly id={value.value} name="section" onClick={(e) => setPageSection({
                                        ...pageSection,
                                        section_id: e,
                                    })} value={value.value}  checked={value.value == pageSection.section_id ? true:false} />
                                            <label for={value.value}></label>

                                        </div>  
                                    </div>

                                )
                            }) : ''} */}
                                    </div>
                                </div>
                                {/* <Grid item xs={6}>
                                <SelectSearch options={sections} id="sections" value={pageSection.section_id}
                                    name="parent" search fuzzySearch
                                    onChange={(e) => setPageSection({
                                        ...pageSection,
                                        section_id: e,
                                    })} placeholder="Choose your Section" />
                            </Grid> */}
                                <Grid item xs={6} className="Delete-1">
                                    <TextField id="outlined-basic" fullWidth placeholder="Common Heading" variant="outlined" value={pageSection.heading} onChange={(e) => setPageSection({
                                        ...pageSection,
                                        heading: e.target.value,
                                    })} name="heading" />
                                </Grid>
                                <Grid item xs={6} className="Delete-1">
                                    <TextField id="outlined-basic" fullWidth placeholder="Common Heading Arabic" variant="outlined" value={pageSection.heading_ar} onChange={(e) => setPageSection({
                                        ...pageSection,
                                        heading_ar: e.target.value,
                                    })} name="heading_ar" />
                                </Grid>
                                <Grid item xs={6} className="Delete-2">
                                    <textarea className="description" placeholder="Common Content" onChange={(e) => setPageSection({
                                        ...pageSection,
                                        content: e.target.value,
                                    })} rows={4} cols={40} value={pageSection.content} name="content" />
                                </Grid>
                                <Grid item xs={6} className="Delete-2">
                                    <textarea className="description" placeholder="Common Content Arabic" onChange={(e) => setPageSection({
                                        ...pageSection,
                                        content_ar: e.target.value,
                                    })} rows={4} cols={40} value={pageSection.content_ar} name="content_ar" />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField id="outlined-basic" fullWidth placeholder="Sort Order" variant="outlined" value={pageSection.sort_order} onChange={(e) => setPageSection({
                                        ...pageSection,
                                        sort_order: e.target.value,
                                    })} name="heading" />
                                </Grid>
                                {/* <Grid item xs={6} >
                                <img width={100} height={100} src={pageSection.image_url}></img>
                                <label>
                                    Upload Image<br></br>
                                    <input type="file" onChange={(e) => setEditImage(e.target.files[0])} className="upload-img" ></input>
                                </label>
                            </Grid> */}
                                <Grid item xs={6} className="update-btn">
                                    <button type="button" onClick={pageSectionUpdate} className="btn btn-primary  "> Update</button>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                    <div class="container-fluid  mt-5">
                        <div className="row bg-smoke">
                            <Grid container spacing={2}>
                                <Grid item xs={6} className="Delete-3">
                                    <TextField id="outlined-basic" fullWidth label="Sub-Title" variant="outlined" onChange={(e) => setHeading(
                                        e.target.value
                                    )} value={heading} name="heading" />
                                </Grid>
                                <Grid item xs={6} className="Delete-3">
                                    <TextField id="outlined-basic" fullWidth label="Sub-Title Arabic" variant="outlined" onChange={(e) => setHeadingAr(
                                        e.target.value
                                    )} value={headingAr} name="heading_ar" />
                                </Grid>
                                {/* <Grid item xs={6}>
                                <TextField id="outlined-basic" fullWidth label="Sort Order" variant="outlined" onChange={(e) => setSortOrder(
                                    e.target.value
                                )} value={sortOrder} name="sort_order" />
                            </Grid> */}
                                <Grid item xs={6} className="Delete-4">
                                    <textarea className="description" placeholder="Content" rows={2} cols={40} onChange={(e) => setContent(
                                        e.target.value
                                    )} value={content} name="content" />
                                </Grid>
                                <Grid item xs={6} className="Delete-4">
                                    <textarea className="description" placeholder="Content Arabic" rows={2} cols={40} onChange={(e) => setContentAr(
                                        e.target.value
                                    )} value={contentAr} name="content_ar" />
                                </Grid>
                                <Grid item xs={6} className="Delete-5">
                                    <label>
                                        Upload Image<br></br>
                                        <input type="file" onChange={(e) => setImage(e.target.files[0])} className="upload-img" ></input>
                                    </label>
                                </Grid>
                                <Grid item xs={12} className="add-btn">
                                    <button type="button" onClick={addPageSectionItem} className="btn btn-primary   "> Add</button>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                    <div class="container-fluid  mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                {pageSectionItems.length > 0 ? pageSectionItems.map((value, index) => {
                                    return (
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Box p={1} color="primary" >
                                                    <Typography
                                                        variant="h6"
                                                        color="primary"
                                                        gutterBottom
                                                    >
                                                        {value.heading}

                                                    </Typography>
                                                </Box>
                                                {/* <Box p={1} color="primary" >
                                                <Typography
                                                    variant="h6"
                                                    color="primary"
                                                    gutterBottom
                                                >
                                                    {value.content}
                                                </Typography>
                                            </Box> */}
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <EditPageSectionItem pageSectionItem={value} pageSectionItemUpdate={pageSectionItemUpdate} pageSectionItemDelete={pageSectionItemDelete} ></EditPageSectionItem>
                                                {/* <EditFaq faq={value} faqUpdate={faqUpdate} faqDelete={faqDelete}></EditFaq> */}
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }) : ''}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default EditPageSection;