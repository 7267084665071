import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useAlert } from "react-alert";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { ErrorHandler } from "../../ui/ErrorHandler";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function AddCategory() {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [nameAr, setNameAr] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("name_ar", nameAr);
        axios("admin/blog_cat_save", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    history.push({
                        pathname: "/blog_category",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/categories"
                    >
                        Categories
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        to="#"
                        aria-current="page"
                    >
                        Add Categories
                    </Link>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <form method="POST" className="category-form">

                            <h3 className="edit-title">Add blog Category <i class="fi fi-rr-add"></i> </h3>
                            <hr></hr>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setName(e.target.value)} value={name} name="name" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setNameAr(e.target.value)} value={nameAr} name="name_ar" />
                                </Grid>
                                <Grid item xs={12}>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary"><i class="fi fi-rr-layer-plus"></i> Add</button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                
                </div>

            </div>
        </div>
    );
}
export default AddCategory;