import React, { useEffect, useState, useRef, useContext } from "react";
//import LoadingOverlay from "react-loading-overlay-ts";
//import RingLoader from "react-spinners/RingLoader";
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import $ from "jquery";
import IsAllowed from "../../Utils/IsAllowed";
function SideBar() {
  const [user, setUser] = useState({});
  function toggle() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $(".sidebar .collapse").collapse("hide");
    }
  }
  return (
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion "
      id="accordionSidebar"
    >
      <Link
        class="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div class="sidebar-brand-icon ">
          {user && user.logo ? (
            <img src={user.logo} height="50px" className="p-2"></img>
          ) : (
            <img src="/logo.png" height="50px" className="p-2"></img>
          )}
        </div>
      </Link>
      <hr class="sidebar-divider my-0" />
      <li class="nav-item active">
        <Link class="nav-link" to="/">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </Link>
      </li>
      <hr class="sidebar-divider" />
      {IsAllowed("list categories") ? (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <i class="fa fa-bullhorn"></i>
            <span>Catalogs</span>
          </a>
          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed("list categories") ? (
                <Link class="collapse-item" to="/categories">
                  Categories
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </li>
      ) : (
        ""
      )}
      {IsAllowed("list services") ? (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
          >
            <i class="fa fa-sitemap"></i>
            <span>Services</span>
          </a>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed("list services") ? (
                <Link class="collapse-item" to="/services">
                  Services
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </li>
      ) : (
        ""
      )}
      {IsAllowed("list users") ? (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseFour"
            aria-expanded="true"
            aria-controls="collapseFour"
          >
            <i class="fa fa-users"></i>
            <span>User Management</span>
          </a>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed("list users") ? (
                <Link class="collapse-item" to="/users">
                  Users
                </Link>
              ) : (
                ""
              )}
              {IsAllowed("view users") ? (
                <Link class="collapse-item" to="/roles">
                  Roles
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </li>
      ) : (
        ""
      )}
      {IsAllowed("list blogs") ||
      IsAllowed("list news") ||
      IsAllowed("list blog categories") ? (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseSix"
            aria-expanded="true"
            aria-controls="collapseSix"
          >
            <i class="fa fa-copy"></i>
            <span>Cms </span>
          </a>
          <div
            id="collapseSix"
            class="collapse"
            aria-labelledby="headingSix"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed("list blog categories") ? (
                <Link class="collapse-item" to="/blog_category">
                  Blog Category
                </Link>
              ) : (
                ""
              )}
              {IsAllowed("list blogs") ? (
                <Link class="collapse-item" to="/blogs">
                  Blog list
                </Link>
              ) : (
                ""
              )}
              {IsAllowed("list news") ? (
                <Link class="collapse-item" to="/news">
                  News list
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </li>
      ) : (
        ""
      )}
      {IsAllowed("list enquiries") ? (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseFive"
            aria-expanded="true"
            aria-controls="collapseFive"
          >
            <i class="fas fa-shopping-cart"></i>
            <span>Enquiries</span>
          </a>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingFive"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed("list enquiries") ? (
                <Link class="nav-link" to="/enquiries">
                  Service Enquiries
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </li>
      ) : (
        ""
      )}
      {IsAllowed("list logs") ? (
        <li class="nav-item ">
          {IsAllowed("list logs") ? (
            <Link class="nav-link" to="/log">
              <i class="fas fa-fw fa-history"></i>
              <span>Logs</span>
            </Link>
          ) : (
            ""
          )}
        </li>
      ) : (
        ""
      )}
      <div class="text-center d-none d-md-inline">
        <button
          class="rounded-circle border-0"
          onClick={toggle}
          id="sidebarToggle"
        ></button>
      </div>
    </ul>
  );
}
export default SideBar;
