import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { ErrorHandler } from "../../ui/ErrorHandler";
import TextEditor from "../../ui/TextEditor";
import "./News.css";
import SelectSearch from 'react-select-search';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Seo from "../Seo/Seo";
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
function EditNews(props) {

    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [news, setNews] = useState({});
    const [categories, setCategories] = useState([]);
    const [image, setImage] = useState(null);
    const [imageAr, setImageAr] = useState(null);
    const [newsId, setNewsId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );


    useEffect(() => {
        getNewsDetails();
    }, [props.match.params.id]);
    const getNewsDetails = () => {
        axios(`admin/news/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setNews(response.data.data.news);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("title", news.title);
        formdata.append("title_ar", news.title_ar ?? '');
        formdata.append("slug", news.slug);
        formdata.append("description", news.description ?? '');
        formdata.append("description_ar", news.description_ar ?? '');
        formdata.append("external_link", news.external_link ?? '');
        formdata.append("image", image);
        formdata.append("id", newsId);
        axios("admin/news_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    history.push({
                        pathname: "/news",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <BLink underline="hover" color="inherit" href="/">
                        Home
                    </BLink>
                    <BLink
                        underline="hover"
                        color="inherit"
                        href="/news"
                    >
                        News
                    </BLink>
                    <BLink
                        underline="hover"
                        color="text.primary"
                        href="/"
                        aria-current="page"
                    >
                        Edit News
                    </BLink>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid  mt-5">
                <div className="row">
                    <div className="col-md-12">
                        {Object.keys(news).length != 0 && (
                            <form method="POST" className="blog-form">
                                <h3 className="edit-title">Edit News <i class="fi fi-rr-file-edit"></i></h3>
                                <hr></hr>
                                <Grid container spacing={2}>

                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="External Link" variant="outlined" onChange={(e) => setNews({
                                            ...news,
                                            external_link: e.target.value,
                                        })} value={news.external_link} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setNews({
                                            ...news,
                                            title: e.target.value,
                                        })} value={news.title} name="title" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setNews({
                                            ...news,
                                            title_ar: e.target.value,
                                        })} value={news.title_ar} name="title_ar" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description<br></br>
                                            <TextEditor defaultValue={news.description} onChange={(e) => setNews({
                                                ...news,
                                                description: e,
                                            })} />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description Arabic<br></br>
                                            <TextEditor defaultValue={news.description_ar} onChange={(e) => setNews({
                                                ...news,
                                                description_ar: e,
                                            })} />
                                        </label>
                                    </Grid>


                                    <Grid item xs={6} >
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload
                                            <VisuallyHiddenInput type="file" onChange={(e) => setImage(e.target.files[0])} />
                                        </Button>

                                        <div className="img-container pt-2">
                                            <img src={news.image} style={{ maxWidth: '100%', width: '200px', height: '100px' }}
                                            ></img>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload(Arabic)
                                            <VisuallyHiddenInput type="file" onChange={(e) => setImageAr(e.target.files[0])} />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </div>
                </div>
            </div>
            <div class="container-fluid  mt-5">
                <Seo page={'News'} pageId={newsId}></Seo>
            </div>
        </div>
    );
}
export default EditNews;