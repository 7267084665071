import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { ErrorHandler } from "../../ui/ErrorHandler";
import TextEditor from "../../ui/TextEditor";
import "./BlogCategory.css";
import SelectSearch from 'react-select-search';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Seo from "../Seo/Seo";
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
function EditBlog(props) {

    const [pageType, setType] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [blog, setBlog] = useState({});
    const [categories, setCategories] = useState([]);
    const [image, setImage] = useState(null);
    const [imageAr, setImageAr] = useState(null);
    const [blogId, setBlogId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );


    useEffect(() => {
        getBlogDetails();
        getCategories();

    }, [props.match.params.id]);
    const getBlogDetails = () => {
        axios(`admin/blogs/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setBlog(response.data.data.blogs);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("title", blog.title);
        formdata.append("title_ar", blog.title_ar ?? '');
        formdata.append("slug", blog.slug);
        formdata.append("description", blog.description ?? '');
        formdata.append("description_ar", blog.description_ar ?? '');
        formdata.append("category_id", blog.category_id);
        formdata.append("image", image);
        formdata.append("id", blogId);
        axios("admin/blog_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    history.push({
                        pathname: "/blogs",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const getCategories = () => {
        axios("admin/blog_categories_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    response.data.data.unshift({ name: 'No Category', value: '0' });
                    setCategories(response.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };
    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <BLink underline="hover" color="inherit" href="/">
                        Home
                    </BLink>
                    <BLink
                        underline="hover"
                        color="inherit"
                        href="/blogs"
                    >
                        Blogs
                    </BLink>
                    <BLink
                        underline="hover"
                        color="text.primary"
                        href="/"
                        aria-current="page"
                    >
                        Edit Blogs
                    </BLink>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid  mt-5">
                <div className="row">
                    <div className="col-md-12">
                        {Object.keys(blog).length != 0 && (
                            <form method="POST" className="blog-form">
                                <h3 className="edit-title">Edit Blogs <i class="fi fi-rr-file-edit"></i></h3>
                                <hr></hr>
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <SelectSearch options={categories} id="category_id" value={blog.category_id} onChange={(e) => setBlog({
                                            ...blog,
                                            category_id: e,
                                        })}
                                            name="parent" search fuzzySearch placeholder="Choose your Category" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Seo url" variant="outlined" onChange={(e) => setBlog({
                                            ...blog,
                                            slug: e.target.value,
                                        })} value={blog.slug} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setBlog({
                                            ...blog,
                                            title: e.target.value,
                                        })} value={blog.title} name="title" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setBlog({
                                            ...blog,
                                            title_ar: e.target.value,
                                        })} value={blog.title_ar} name="title_ar" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description<br></br>
                                            <TextEditor defaultValue={blog.description} onChange={(e) => setBlog({
                                                ...blog,
                                                description: e,
                                            })} />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description Arabic<br></br>
                                            <TextEditor defaultValue={blog.description_ar} onChange={(e) => setBlog({
                                                ...blog,
                                                description_ar: e,
                                            })} />
                                        </label>
                                    </Grid>


                                    <Grid item xs={6} >
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload
                                            <VisuallyHiddenInput type="file" onChange={(e) => setImage(e.target.files[0])} />
                                        </Button>

                                        <div className="img-container pt-2">
                                            <img src={blog.image} style={{ maxWidth: '100%', width: '200px', height: '100px' }}
                                            ></img>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload(Arabic)
                                            <VisuallyHiddenInput type="file" onChange={(e) => setImageAr(e.target.files[0])} />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </div>

                </div>
            </div>
            <div class="container-fluid  mt-5">
                <Seo page={'Blogs'} pageId={blogId}></Seo>
            </div>

        </div>
    );
}
export default EditBlog;