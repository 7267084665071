import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import { ErrorHandler } from "../../ui/ErrorHandler";
import TextEditor from "../../ui/TextEditor";
import "./Services.css";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SelectSearch from 'react-select-search';
import CustomAutocomplete from './../../ui/CustomAutocomplete';
import { Category } from "@material-ui/icons";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});




function AddService() {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [parentId, setParentId] = useState({ id: 0, name: "No parent" });
    const [categoryId, setCategoryId] = useState(0);
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState('');
    const [nameAr, setNameAr] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [shortDescriptionAr, setShortDescriptionAr] = useState('');
    const [isFeatured, setIsFeatured] = useState(0);
    const [image, setImage] = useState(null);
    const [imageAr, setImageAr] = useState(null);
    const types = [
        { name: 'Dynamic Content ', value: '0' },
        { name: 'Static Content ', value: '1' }
    ];
    const [pageType, setType] = useState(0); // Initialize parentId state to null
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("name_ar", nameAr);
        formdata.append("description", description);
        formdata.append("description_ar", descriptionAr);
        formdata.append("short_description", shortDescription);
        formdata.append("short_description_ar", shortDescriptionAr);
        formdata.append("page_type", pageType);
        formdata.append("parent_id", parentId);
        formdata.append("is_featured", isFeatured ? 1 : 0);
        formdata.append("category_id", categoryId);
        formdata.append("image", image);

        axios("admin/service_save", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };


    const getCategories = () => {
        axios("admin/category_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setCategories(response.data.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };


    useEffect(() => {
        getCategories();
    }, []);
    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/services"
                    >
                        Services
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                        to="#"
                    >
                        Add Services
                    </Link>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <form method="POST" className="service-form">
                            <h3 className="edit-title">Add Services <i class="fi fi-rr-add"></i> </h3>
                            <hr></hr>
                            <Grid container spacing={2}>
                                {/* <Grid item xs={12}>
                                    <SelectSearch options={services} id="parent" value={parentId} onChange={(e) => setParentId(e)}
                                        name="parent" search fuzzySearch placeholder="Choose your Parent Service" />
                                </Grid> */}
                                <Grid item xs={6}>
                                    <SelectSearch options={categories} id="category" value={categoryId} onChange={(e) => setCategoryId(e)}
                                        name="category_id" search fuzzySearch placeholder="Choose Category" />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectSearch options={types} id="page_type" value={pageType} onChange={(e) => setType(e)}
                                        name="page_type" search fuzzySearch placeholder="Choose your Page Type" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setName(e.target.value)} value={name} name="name" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setNameAr(e.target.value)} value={nameAr} name="name_ar" />
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="label-description">
                                        Description<br></br>
                                        <TextEditor defaultValue={description} onChange={(e) => setDescription(e)} />
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="label-description">
                                        Description Arabic<br></br>
                                        <TextEditor defaultValue={descriptionAr} onChange={(e) => setDescriptionAr(e)} />
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="label-description">
                                        Short Description<br></br>
                                        <textarea className="description" rows={4} cols={40} onChange={(e) => setShortDescription(e.target.value
                                        )} value={shortDescription} name="short_description" />
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className="label-description">
                                        Short Description Arabic<br></br>
                                        <textarea className="description" rows={4} cols={40} onChange={(e) => setShortDescriptionAr(e.target.value
                                        )} value={shortDescriptionAr} name="short_description_ar" />
                                    </label>
                                </Grid>
                                <Grid item xs={6} >
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload
                                        <VisuallyHiddenInput type="file" onChange={(e) => setImage(e.target.files[0])} />
                                    </Button>

                                </Grid>
                                <Grid item xs={6} >
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload(Arabic)
                                        <VisuallyHiddenInput type="file" onChange={(e) => setImageAr(e.target.files[0])} />
                                    </Button>
                                </Grid>
                                <Grid item xs={6} >
                                    <label className="label-description">
                                        Is Featured
                                        <Switch
                                            checked={isFeatured}
                                            onChange={() => setIsFeatured(!isFeatured)}
                                            color="primary"
                                        />
                                    </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary"><i class="fi fi-rr-layer-plus"></i> Add</button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddService;