import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import RingLoader from "react-spinners/RingLoader";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { ErrorHandler } from "../../ui/ErrorHandler";
import SelectSearch from 'react-select-search';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from "@mui/material";
import EditSeo from "./EditSeo";

import "./Seo.css";

function Seo({ page, pageId }) {

    const alert = useAlert();
    const [loading, setLoading] = useState('');
    const [keyword, setKeyword] = useState('');
    const [title, setTitle] = useState('');
    const [languageId, setLanguageId] = useState(1);
    const [description, setDescription] = useState('');
    const [languages, setLanguages] = useState([{ value: 1, name: "English" }, { value: 2, name: "Arabic" }]);

    const [seo, setSeo] = useState([]);
    useEffect(() => {
        getSeo();
    }, [pageId]);



    const getSeo = () => {
        let formData = new FormData();
        formData.append("page", page);
        formData.append("page_id", pageId);
        axios(`admin/seo`, {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setSeo(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const seoAdd = (event) => {
        event.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append("meta_title", title);
        formData.append("meta_description", description);
        formData.append("meta_keyword", keyword);
        formData.append("lang_id", languageId);
        formData.append("store_id", 1);
        formData.append("page", page);
        formData.append("page_id", pageId);
        axios("admin/seo_save", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getSeo();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const seoDelete = (id) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("id", id);

        axios("admin/seo_delete", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getSeo();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const seoUpdate = (seo) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("meta_title", seo.meta_title);
        formData.append("meta_description", seo.meta_description);
        formData.append("meta_keyword", seo.meta_keyword);
        formData.append("id", seo.id);

        axios("admin/seo_update", {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getSeo();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };



    return (
        <LoadingOverlay
            active={loading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 0.9)",
                }),
                spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                    },
                }),
            }}
            spinner={<RingLoader loading={loading} color="#36d7b7" />}
            text="..."
        >
            <div className="bg-smoke mb-3">
                <div className="col-md-12 SEO">
                    <form method="POST" className="service-form">
                        <h3 className="edit-title">Add SEO Contents <i class="fi fi-rr-add"></i></h3>
                        <hr></hr>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <SelectSearch options={languages} id="language" value={languageId} onChange={(e) => setLanguageId(e)}
                                    name="language_id" search fuzzySearch placeholder="Choose Language" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="outlined-basic" fullWidth label="Meta Title" variant="outlined" onChange={(e) => setTitle(e.target.value)} value={title} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="outlined-basic" fullWidth label="Meta Keyword" variant="outlined" onChange={(e) => setKeyword(e.target.value)} value={keyword} />
                            </Grid>
                            <Grid item xs={12}>
                                <label className="label-description">
                                    Meta Description<br></br>
                                    <textarea className="description" rows={4} cols={40} onChange={(e) => setDescription(e.target.value
                                    )} value={description} />
                                </label>
                            </Grid>

                            <Grid item xs={12}>
                                <button type="button" onClick={seoAdd} className="btn btn-primary"><i class="fi fi-rr-layer-plus"></i> Add</button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div className="col-md-12">


                    {seo.length > 0 ? seo.map((value, index) => {
                        return (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Box p={1} color="primary" >
                                        <Typography
                                            variant="h6"
                                            color="primary"
                                            gutterBottom
                                        >
                                            {value.language.name}

                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <EditSeo seo={value} seoUpdate={seoUpdate} seoDelete={seoDelete}></EditSeo>
                                </AccordionDetails>
                            </Accordion>
                        )
                    }) : ''}
                </div>
            </div>

        </LoadingOverlay>
    )
}

export default Seo